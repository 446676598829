<script lang="ts">
export default {
    name: 'ForgotPassword',
    setup() {
        const runtimeConfig = useRuntimeConfig()

        definePageMeta({ layout: 'empty' })
        useHead({
            title: 'Mot de passe oublié',
            meta: [
                { name: 'description', content: 'Mot de passe oublié' },
            ],
        })

        return {
            runtimeConfig,
        }
    },
    data: () => ({
        email: null,
        resetting: false,
    }),
    methods: {
        async forgetPassword() {
            this.resetting = true
            const route = {
                url: 'reset-password',
                method: 'POST',
                body: { email: this.email },
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            }
            try {
                await this.$api.setRequest(route, true)
                this.$showToaster({ title: 'Réinitialisation', text: `Si votre compte existe, un e-mail pour réinitialiser votre mot de passe a été envoyé à ${this.email}. `, color: 'success', icon: 'mdi-check-circle', timer: -1 })
            }
            catch (error) {
                const errorText = error.data && error.data.error && error.data.error.email ? error.data.error.email : `L'envoi du mail de réinitialisation a échoué. Veuillez vérifier l'adresse e-mail renseignée.`
                this.$showToaster({ title: 'Échec d\'envoi', text: errorText, color: 'error', icon: 'mdi-alert-circle', timer: -1 })
            }

            this.resetting = false
        },
    },
}
</script>

<template>
    <div>
        <v-container class="pa-0">
            <v-row
                class="h-screen"
                justify="center"
                align="center"
            >
                <v-col cols="12">
                    <v-card
                        class="mx-auto pa-10"
                        elevation="8"
                        max-width="600"
                        rounded="lg"
                    >
                        <v-form
                            validate-on="submit lazy"
                            @submit.prevent="forgetPassword"
                        >
                            <div class="w-100 text-center">
                                <img
                                    class="mx-auto"
                                    src="@nuxtcommon/assets/images/logo.png"
                                    alt="Pildom"
                                >
                            </div>

                            <p class="text-subtitle-2 text-center py-6 font-italic">
                                Renseigner l'adresse e-mail associée à votre compte, et nous vous enverrons un e-mail permettant de réinitialiser votre mot de passe.
                            </p>

                            <div class="text-subtitle-1 text-medium-emphasis">
                                E-mail
                            </div>

                            <v-text-field
                                v-model="email"
                                density="compact"
                                prepend-inner-icon="mdi-email-outline"
                                variant="outlined"
                            />

                            <v-btn
                                type="submit"
                                :loading="resetting"
                                block
                                class="my-4"
                                color="primary"
                                size="large"
                            >
                                Mot de passe oublié
                            </v-btn>
                            <v-btn
                                block
                                class="my-4"
                                color="primary"
                                size="large"
                                variant="tonal"
                                @click="$router.replace('/#connexion')"
                            >
                                Retourner à l'écran de connexion
                            </v-btn>
                        </v-form>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
